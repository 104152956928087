import customerLogo from './assets/aws-logo-on-dark-bg.png';

const config = {
  HEADER_TITLE: "",
  HEADER_LOGO: customerLogo,
  // MAX_ATTACHMENT_SIZE: 5000000,
  apiGateway: {
    API_NAME: "PrototypeQualityAPI",
    REGION: "us-east-1",
    API_URL: "https://f74vmsnyxe.execute-api.us-east-1.amazonaws.com/play"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_IegYixCGc",
    APP_CLIENT_ID: "1lf6n8bpi6o7ca2tg472kqtlkg",
    IDENTITY_POOL_ID: "us-east-1:d409a9f8-0e3a-4590-b04e-c7df21c8731d",
    OAUTH: {
      APP_CLIENT_DOMAIN: "play-qap-domain.auth.us-east-1.amazoncognito.com",
      SCOPE: ['email', 'openid'],
      REDIRECT_SIGN_IN: "https://play.quality.prototyping.aws.dev/",
      REDIRECT_SIGN_OUT: "https://play.quality.prototyping.aws.dev/",
      RESPONSE_TYPE: 'code'
    }
  },
  environment: {
    profileName: "play",
    webComponentsPath: "playground/",
  },
  feedback: {
    ASANA_FORM_URL: 'https://form.asana.com/?k=iTcQ3yA1PkuX6qL3jA3O4g&d=8442528107068'
  }
};

export default config;