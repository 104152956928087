// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React, { Component } from 'react';
import { Table, Container, Header, Icon, Segment, Label } from 'semantic-ui-react'
import { API } from "aws-amplify";
import { DashboardItem } from './PrototypeQualityDashboardItem';
import { Loader } from "./Loader"
import { Link } from 'react-router-dom';


export default class PrototypeQualityDashboard extends Component {

    static colors = ['red', 'purple', 'yellow', 'orange', 'green', 'teal', 'olive', 'violet', 'blue', 'pink', 'brown', 'grey', 'black'];

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_error: false,
            dashboard_data: []
        }
    }

    componentDidMount() {
        try {
            API.get("PrototypeQualityAPI", "/proto_quality_dashboard")
                .then(response => {
                    console.log(response)
                    this.setState({
                        dashboard_data: response,
                        loading_error: false,
                        loading: false
                    })
                })
                .catch(error => {
                    this.setState({
                        loading_error: true,
                        loading: false
                    })
                    console.error(error)
                });
        }
        catch (error) {
            this.setState({
                loading_error: true,
                loading: false
            })
            console.error(error)
        }
    }

    renderHeader() {
        return this.state.dashboard_data['headers'].map(item => {
            return (<Table.HeaderCell key={item['id']} textAlign="center" verticalAlign="middle">{item['title']}</Table.HeaderCell>)
        });
    }

    generateDashboardItem(quality_category_findings) {
        console.log(quality_category_findings);
        return {
            didRunQualityAnalysis: Object.keys(quality_category_findings).length !== 0,
            totalNumOfFindings: parseInt(quality_category_findings?.total_number_of_findings || '0'),
            analysisDateTime: quality_category_findings?.review_date_time_utc,
            severityStats: quality_category_findings?.severity_stats,
            result: quality_category_findings?.result || 'SUCCESS',
            latestFindingsReview: quality_category_findings?.latest_findings_review || {},
        }
    }

    renderQualityDimensionCells(key, quality_category_findings) {
        const dashboardItem = this.generateDashboardItem(quality_category_findings);
        const repositoryName = quality_category_findings?.prototype_details?.code_repository_name;
        const categoryId = quality_category_findings?.category_id;
        const categoryReportRoute = `/latest_quality_findings_for_repo_for_category/${repositoryName}/${categoryId}`
        return (
            <Table.Cell key={key+'_tablecell'} 
                item={quality_category_findings}
                textAlign="center"
                verticalAlign="middle">
                { 
                    dashboardItem['didRunQualityAnalysis'] && 
                    <Link 
                        as="a" 
                        to={categoryReportRoute}>
                        <DashboardItem dashboardItem={dashboardItem}/>
                    </Link>
                }
                { 
                    !dashboardItem['didRunQualityAnalysis'] && 
                    <DashboardItem dashboardItem={dashboardItem}/>
                }

            </Table.Cell>
        )
    }

    // Generate a number between 0 and 13 from an input string
    generateRandomColor(str) {
        const idx = parseInt(str.split('').map(c => {return c.charCodeAt(0)%10}).join('').substring(0,5))%PrototypeQualityDashboard.colors.length;
        return PrototypeQualityDashboard.colors[idx];
    } 

    cutString(str, size) {
        return str.length > size ? str.substring(0, size - 3) + '...' : str;
    }

    renderDashboardRows() {
        let rows = []
        for (const repo_entry of this.state.dashboard_data['content']) {
            const { repository_name, repository_origin = false, quality_checks } = repo_entry;
            const prototypeName = quality_checks[0]['prototype_details']['prototype_name']
            const techLeadLogin = quality_checks[0]['prototype_details']['tech_lead_login']
            const fullReportRoute = `/latest_quality_findings_for_repo/${repository_name}`
            const repoNameMaxNumChars = 36;
            const collectionNameMaxNumChars = 20;
            const repoOriginTitle = repository_origin && repository_origin['organization'].length > 0 ? 
                repository_origin['name'] + ' from ' + repository_origin['organization'] : ''

            let cells = [
                <Table.Cell  key={repository_name+'_tablecell'}>
                        <Segment.Group>
                            <Segment>
                                <Label 
                                    as='a' 
                                    size="medium"
                                    attached='top left'
                                    title={repository_name}
                                >
                                    {this.cutString(repository_name, repoNameMaxNumChars)}
                                </Label>
                            </Segment>
                            <Segment basic>
                                {prototypeName} (@{techLeadLogin})
                                <br />
                                <Icon name="pdf file outline" />
                                <Link 
                                    as="a"
                                    to={fullReportRoute}
                                    target="_blank"
                                    >
                                    full analysis report
                                </Link> 
                            </Segment>
                            {
                                repository_origin &&
                                    <Label 
                                        as='a'
                                    color={this.generateRandomColor(repository_origin['collection'])}
                                        basic
                                        attached='bottom right'
                                        size="mini"
                                        title={repoOriginTitle}
                                    >
                                        {this.cutString(repository_origin['collection'], collectionNameMaxNumChars)}
                                    </Label>
                                }
                        </Segment.Group>
                </Table.Cell>]
            let quality_cells = this.state.dashboard_data['headers'].map(item => {
                const qualityCheckIndex = quality_checks.findIndex((element) => element['category_id'] === item.type);
                if (qualityCheckIndex !== -1) {
                    const item = quality_checks[qualityCheckIndex];
                    const key = repository_name + '#' + item["category_id"] + '#' + item["review_date_time_utc"] + '#' + qualityCheckIndex
                    return this.renderQualityDimensionCells(key, item)
                } else {
                    const key = repository_name + '#' + item.type + '#N/A#' + qualityCheckIndex 
                    return this.renderQualityDimensionCells(key, {})
                }
            })
            rows.push((<Table.Row key={repository_name+'table_row'}>{cells.concat(quality_cells)}</Table.Row>))
        }
        return rows
    }

    renderDashboard() {
        let numOfEntries = Object.keys(this.state.dashboard_data['content']).length
        return (
            <Container>
                <Header as="h1">Quality Analysis Dashboard</Header>
                <p>(*) Important: You must push a change to your repo to see it listed below (allow a few minutes for the analyses to run)</p>
                {numOfEntries > 0 &&
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Code Repository</Table.HeaderCell>
                                {this.renderHeader()}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.renderDashboardRows()}
                        </Table.Body>
                    </Table>}
                {numOfEntries === 0 && <div>No prototypes found.</div>}
            </Container>
        )
    }

    // Components to explore: Progress (for upload progress), Message (for errors)
    render() {
        return (
            <div>
                {!this.state.loading && this.renderDashboard()}
                {
                    this.state.loading &&
                    <Loader message='"Quality is not act.  It is a habit" ~Aristotle'/>
                }
            </div>
        )
    }
}
