// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React, { Component } from "react";
import { API } from "aws-amplify";
import { Header, Container, Segment, Message, Table, Icon, Accordion, Form, Checkbox, Dropdown, Grid, TextArea, Button, Label, Modal, Divider } from 'semantic-ui-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
// import hljs from 'highlight.js'; // disabling as syntax highlighting is slowing down rendering
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import "./QualityFindingsPerCategoryView.css";

export default class QualityFindingsPerCategoryView extends Component {

  API_NAME = "PrototypeQualityAPI";
  API_ANALYSIS_REVIEW_PATH = "/analysis/review";
  API_FETCH_SOURCE_CODE_PATH = "/repo/code"
  API_GEN_AI_CODE_FIX_SUGGESTION_PATH = "/ai/codefix";

  REVIEW_TYPE_SEVERITY = "severity";
  REVIEW_TYPE_SUPPRESSION = "suppression";

  REVIEW_ACTION_AGREE_WITH_DEVELOPER_REVIEW = "agree-with-developer-review";
  REVIEW_ACTION_AGREE_WITH_ORIGINAL_ASSESSMENT_FOR_FILE = "agree-with-original-assessment-for-file";
  REVIEW_ACTION_AGREE_WITH_ORIGINAL_ASSESSMENT_FOR_REPO = "agree-with-original-assessment-for-repo";
  REVIEW_ACTION_OWN_ASSESSMENT = "provide-own-assessment";

  REVIEW_CLASSIFICATION_INCOMPLETE_OR_NOT_STARTED_REVIEW = 'review-incomplete-or-not-started';
  REVIEW_CLASSIFICATION_NEW_REVIEW_COMPLETED = 'review-completed-new';
  REVIEW_CLASSIFICATION_LATEST_FETCHED_REVIEW_SAMEAS_ORIGINAL = 'review-fetched-same-as-original';
  REVIEW_CLASSIFICATION_LATEST_FETCHED_REVIEW_DIFFERENT_FROM_ORIGINAL = 'review-fetched-different-from-original';

  SEVERITY_CRITICAL = 'critical';
  SEVERITY_HIGH = 'high';
  SEVERITY_MEDIUM = 'medium';
  SEVERITY_LOW = 'low';
  SEVERITY_UNKNOWN = 'unknown';

  SCOPE_ORGANIZATION = "organization";
  SCOPE_REPO = "repo";
  SCOPE_FILE = "file";

  ALL_FILES = "__all-files__";

  USER_ROLE_DEVELOPER = "developer";
  USER_ROLE_EXPERT = "expert";
  NO_REVIEWER = "no-reviewer"

  userRoleMapping = {
    'developer': this.USER_ROLE_DEVELOPER,
    'protosec_champion': this.USER_ROLE_EXPERT,
    'reviewer': this.USER_ROLE_EXPERT,
  };

  SOURCE_CODE_NOT_FETCHED = "__source_code_not_fetched__"
  SOURCE_CODE_FETCHING = "__source_code_fetching__"
  SOURCE_CODE_FETCHING_ERROR = "__source_code_fetching_error__"
  SOURCE_CODE_FETCHED = "__source_code_fetched__"

  SOURCE_CODE_FIX_GENAI_NOT_FETCHED = "__source_code_fix_genai_not_fetched__"
  SOURCE_CODE_FIX_GENAI_FETCHING = "__source_code_fix_genai_fetching__"
  SOURCE_CODE_FIX_GENAI_FETCHING_ERROR = "__source_code_fix_genai_fetching_error__"
  SOURCE_CODE_FIX_GENAI_FETCHED = "__source_code_fix_genai_fetched__"

  GENAI_MAX_PROMPT_SIZE = 4096

  BEDROCK_MODEL_ANTHROPIC_CLAUDE_V1 = "anthropic.claude-v1"
  BEDROCK_MODEL_ANTHROPIC_CLAUDE_V1_NAME = "Amazon Bedrock- Anthropic Claude V1 (v1.3)"
  BEDROCK_MODEL_ANTHROPIC_CLAUDE_V2 = "anthropic.claude-v2"
  BEDROCK_MODEL_ANTHROPIC_CLAUDE_V2_NAME = "Amazon Bedrock- Anthropic Claude V2"
  BEDROCK_MODEL_AMAZON_TITAN_TG1_LARGE = "amazon.titan-tg1-large"
  BEDROCK_MODEL_AMAZON_TITAN_TG1_LARGE_NAME = "Amazon Bedrock- Amazon Titan Large (v1.01)"
  
  BEDROCK_MODEL_DEFAULT = this.BEDROCK_MODEL_ANTHROPIC_CLAUDE_V2

  genAISupportedModels = [
    {
      key: this.BEDROCK_MODEL_ANTHROPIC_CLAUDE_V2,
      text: this.BEDROCK_MODEL_ANTHROPIC_CLAUDE_V2_NAME,
      value: this.BEDROCK_MODEL_ANTHROPIC_CLAUDE_V2
    },
    {
      key: this.BEDROCK_MODEL_ANTHROPIC_CLAUDE_V1,
      text: this.BEDROCK_MODEL_ANTHROPIC_CLAUDE_V1_NAME,
      value: this.BEDROCK_MODEL_ANTHROPIC_CLAUDE_V1
    },
    {
      key: this.BEDROCK_MODEL_AMAZON_TITAN_TG1_LARGE,
      text: this.BEDROCK_MODEL_AMAZON_TITAN_TG1_LARGE_NAME,
      value: this.BEDROCK_MODEL_AMAZON_TITAN_TG1_LARGE
    },
  ]

  severityValues = [this.SEVERITY_CRITICAL, this.SEVERITY_HIGH, this.SEVERITY_MEDIUM, this.SEVERITY_LOW, this.SEVERITY_UNKNOWN];
  originalFindingAssessments = {}

  userId = "<unknown>"; // default; will be overwritten by the user profile
  userRole = this.USER_ROLE_DEVELOPER;  // default; will be overwritten by the user profile

  submissionData = {}
  fetchedSourceCode = {}

  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      reviewSubmission: {
        showSubmissionModal: false,
        submittingReviews: false,
        submissionHasWarning: false,
        submissionMessage: "",
        lastSubmissionDate: null
      },
    }
  }

  async qualityReviewsSetup() {

    const getOriginalSeverity = (finding) => {
      if ("original_severity" in finding)
        return finding.original_severity;
      return finding.severity;
    }

    const { data, userProfile } = this.props
    // backward compatibility: for old repos, the branch_name attribute is 
    // not available so we do our best to guess the branch name :)
    const { branch_name = 'master', commit_id, category_id, prototype_details } = data;
    this.userId = userProfile.email;
    this.userRole = this.userRoleMapping[userProfile.role];
    this.submissionData = {
      reviewerId: this.userId,
      reviewerRole: this.userRole,
      repositoryName: prototype_details.code_repository_name,
      categoryId: category_id,
      branchName: branch_name,
      commitId: commit_id,
      reviews: {}
    }
    data.findings.forEach((artifact) => {
      const { artifact_findings, suppressed_findings } = artifact;
      const all_artifact_findings = (artifact_findings || []).concat(suppressed_findings || [])
      all_artifact_findings.forEach((finding) => {
        this.originalFindingAssessments[finding.finding_category_id] = getOriginalSeverity(finding);
      })
    });
    this.populateQualityReviewsState();
  }

  async componentDidMount() {
    if (this.props.enableQualityReviews) {
      await this.qualityReviewsSetup();
    }
  }

  latestRoleThatReviewedTheFinding(findingCategoryId, artifactId, fetchedReviews) {
    const hasFindingBeingReviewedByRole = userRole => {
      const { reviews } = fetchedReviews[userRole];
      if (reviews !== undefined) {
        if (findingCategoryId in reviews) {
          return (this.ALL_FILES in reviews[findingCategoryId] ||
            artifactId in reviews[findingCategoryId]);
        }
      }
      return false;
    }
    const userRoles = [this.userRole, this.theOtherUserRole()]
    for (const userRole of userRoles) {
      if (hasFindingBeingReviewedByRole(userRole))
        return userRole;
    }
    return this.NO_REVIEWER;
  }

  // Copy all fetched reviews into the appropriate place in the findingsUnderReview state
  getQualityReviewsForUserState(findingCategoryId, userRole, fetchedReviews) {
    const { reviews } = fetchedReviews[userRole];
    // the finding has been reviewed by the userRole
    if (reviews !== undefined && findingCategoryId in reviews) {
      const reviewsForRole = JSON.parse(JSON.stringify(reviews[findingCategoryId]));
      for (const key in reviewsForRole) {
        if (key !== 'reviewScope') {
          reviewsForRole[key].reviewCompleted = true;
          reviewsForRole[key].reviewLastUpdate = new Date(reviewsForRole[key].reviewLastUpdate);
        }
      }
      return reviewsForRole;
    }
    return {
      [this.ALL_FILES]: {
        reviewCompleted: false,
      }
    }
  }

  populateQualityReviewsForUserForArtifactState(findingsUnderReview, findingCategoryId, userRole, artifactId) {
    if (!(this.ALL_FILES in findingsUnderReview[findingCategoryId][userRole])) {
      if (!(artifactId in findingsUnderReview[findingCategoryId][userRole])) {
        findingsUnderReview[findingCategoryId][userRole][artifactId] = {
          reviewCompleted: false,
        }
      }
    }
  }

  async populateQualityReviewsState() {
    const { prototype_details, category_id } = this.props.data;
    const queryString = `repositoryName=${prototype_details.code_repository_name}&categoryId=${category_id}`
    try {
      console.log(`Calling API: ${this.API_ANALYSIS_REVIEW_PATH}?${queryString}`)
      const fetchedReviews = await API.get(this.API_NAME, `${this.API_ANALYSIS_REVIEW_PATH}?${queryString}`);
      console.log(fetchedReviews);
      let findingsUnderReview = {};
      const reviewPanelActivation = {};
      const lastestReviewerRole = {};
      const sourceCodeLoadingState = {};
      const genAISourceCodeFixSuggestionLoadingState = {};
      this.props.data.findings.forEach((artifact) => {
        const { artifact_name, artifact_location, artifact_findings, suppressed_findings } = artifact;
        const artifactId = artifact_location + artifact_name;
        sourceCodeLoadingState[artifactId] = this.SOURCE_CODE_NOT_FETCHED;
        let all_findings = artifact_findings;
        // for the expert role, we show suppressed findings so they can review it if needes, eg, to unsuppress them
        if (this.userRole === this.USER_ROLE_EXPERT && suppressed_findings !== undefined) {
          all_findings = all_findings.concat(suppressed_findings);
        }
        all_findings.forEach((finding) => {
          if (!(finding.finding_category_id in reviewPanelActivation)) {
            reviewPanelActivation[finding.finding_category_id] = {};
          }
          if (!(finding.finding_category_id in lastestReviewerRole)) {
            lastestReviewerRole[finding.finding_category_id] = {};
          }
          if (!(finding.finding_category_id in genAISourceCodeFixSuggestionLoadingState)) {
            genAISourceCodeFixSuggestionLoadingState[finding.finding_category_id] = {};
          }
          reviewPanelActivation[finding.finding_category_id][artifactId] = false;
          lastestReviewerRole[finding.finding_category_id][artifactId] = this.latestRoleThatReviewedTheFinding(finding.finding_category_id, artifactId, fetchedReviews);

          genAISourceCodeFixSuggestionLoadingState[finding.finding_category_id][artifactId] = {
            displayPanel: false,
            loadingState: this.SOURCE_CODE_FIX_GENAI_NOT_FETCHED,
            prompt: null,
            result: null,
            model: this.BEDROCK_MODEL_DEFAULT,
          }
          if (!(finding.finding_category_id in findingsUnderReview)) {
            findingsUnderReview[finding.finding_category_id] = {
              [this.USER_ROLE_DEVELOPER]: this.getQualityReviewsForUserState(finding.finding_category_id, this.USER_ROLE_DEVELOPER, fetchedReviews),
              [this.USER_ROLE_EXPERT]: this.getQualityReviewsForUserState(finding.finding_category_id, this.USER_ROLE_EXPERT, fetchedReviews),
            }
          }
          this.populateQualityReviewsForUserForArtifactState(findingsUnderReview, finding.finding_category_id, this.userRole, artifactId);
          this.populateQualityReviewsForUserForArtifactState(findingsUnderReview, finding.finding_category_id, this.theOtherUserRole(), artifactId);
        });
      });
      console.log(findingsUnderReview);
      this.setState({
        fetchedReviews: fetchedReviews,
        findingsReviewAPIFetchTime: new Date(),
        findingsUnderReview: findingsUnderReview,
        reviewPanelActivation: reviewPanelActivation,
        latestReviewerRole: lastestReviewerRole,
        sourceCodeLoadingState: sourceCodeLoadingState,
        genAISourceCodeFixSuggestionLoadingState: genAISourceCodeFixSuggestionLoadingState
      });
    } catch (error) {
      const errorMessage = `Unexpected error calling API: ${this.API_ANALYSIS_REVIEW_PATH}?${queryString}. Error: ${error}`
      console.error(errorMessage);
      throw error;
    }
  }

  renderSourceDetails() {
    const { sources } = this.props.data;
    return (
      <Segment basic>
        <Header as="h4">Analysis Tool(s)</Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sources.map((key, index) => {
              const { name, description, link } = key;
              return (
                <Table.Row key={index}>
                  <Table.Cell><a href={link} target="_blank" rel="noopener noreferrer">{name}</a></Table.Cell>
                  <Table.Cell>{description}</Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Segment>
    );
  }

  sortArtifacts(artifacts) {
    function compare(item1, item2) {
      const artifact_location1 = item1['artifact_location'].toLowerCase()
      const artifact_location2 = item2['artifact_location'].toLowerCase()
      if (artifact_location1 < artifact_location2) return -1;
      if (artifact_location1 > artifact_location2) return 1;
      const artifact_name1 = item1['artifact_name'].toLowerCase()
      const artifact_name2 = item2['artifact_name'].toLowerCase()
      if (artifact_name1 < artifact_name2) return -1;
      if (artifact_name1 > artifact_name2) return 1;
      return 0;
    }
    return artifacts.sort(compare);
  }

  sortArtifactFindings(findings) {
    const severityMap = {
      'critical': 0,
      'high': 1,
      'medium': 2,
      'low': 3,
      'unknown': 100
    };
    function compare(item1, item2) {
      const severity1 = severityMap[item1['severity'].toLowerCase()]
      const severity2 = severityMap[item2['severity'].toLowerCase()]
      if (severity1 < severity2) return -1;
      if (severity1 > severity2) return 1;
      const startLine1 = item1['line_numbers'].length > 0 ? parseInt(item1['line_numbers'][0]) : parseInt(item1['start_line']);
      const startLine2 = item2['line_numbers'].length > 0 ? parseInt(item2['line_numbers'][0]) : parseInt(item2['start_line']);
      if (startLine1 > startLine2) return 1;
      if (startLine2 > startLine1) return -1;
      return 0;
    }
    return findings.sort(compare);
  }

  getFindingKey(findingCategoryId, artifactLocation, userRole = this.userRole) {
    return this.ALL_FILES in this.state.findingsUnderReview[findingCategoryId][userRole] ? this.ALL_FILES : artifactLocation;
  }

  renderErrorMessage() {
    const { result_msg } = this.props.data;
    return (
      <Segment basic>
        <Container>
          <Header as="h3" color="black">
            <Icon name="window close outline" />
            Error Message
          </Header>
        </Container>
        <Container>
          <Message>
            {result_msg}
          </Message>
        </Container>
      </Segment>
    )
  }

  isReviewReadyForSubmission(reviewStructure, findingKey) {
    if (findingKey in reviewStructure) {
      const { reviewScope } = reviewStructure;
      const { reviewType, reviewValue, reviewReason } = reviewStructure[findingKey];
      return (reviewScope !== undefined && reviewType !== undefined && (reviewReason !== undefined && reviewReason.trim().length > 0))
        && (reviewType === this.REVIEW_TYPE_SUPPRESSION || (reviewType === this.REVIEW_TYPE_SEVERITY && reviewValue !== undefined
          && reviewValue !== null && reviewValue !== ""));
    }
    return false
  }

  getArtifactLocations(findingCategoryId) {
    const { findings } = this.props.data;
    let output = [];
    findings.forEach((item) => {
      const { artifact_findings, suppressed_findings } = item;
      const all_artifact_findings = (artifact_findings || []).concat(suppressed_findings || [])
      let includeFile = all_artifact_findings.some(function (object) {
        return object.finding_category_id === findingCategoryId;
      });
      if (includeFile)
        output.push(item['artifact_location'] + item['artifact_name']);
    });
    return output;
  }

  formatDateTimeUTCToLocalTimeZone(date) {
    const momentDate = moment(date).local();
    return momentDate.format('MMMM Do YYYY, h:mm:ss a');

  }

  isAPerRepoReview(findingCategoryId, userRole = this.userRole) {
    return this.ALL_FILES in this.state.findingsUnderReview[findingCategoryId][userRole]
  }

  isAPerFileReview(findingCategoryId, userRole = this.userRole) {
    const keys = Object.keys(this.state.findingsUnderReview[findingCategoryId][userRole]);
    for (const key in keys) {
      if (key !== this.ALL_FILES && key !== "reviewScope") {
        return true;
      }
    }
    return false;
  }

  theOtherUserRole() {
    return this.userRole === this.USER_ROLE_EXPERT ? this.USER_ROLE_DEVELOPER : this.USER_ROLE_EXPERT;
  }

  roleHasReviewedFinding(findingCategoryId, userRole, artifactLocation) {
    const review = this.state.findingsUnderReview[findingCategoryId][userRole];
    const findingKey = this.getFindingKey(findingCategoryId, artifactLocation, userRole);
    return (review !== undefined && review[findingKey].reviewCompleted);
  }

  getOriginalFindingSeverity(findingCategoryId) {
    return this.originalFindingAssessments[findingCategoryId];
  }


  updateReviewPanelActivationState(findingCategoryId, activationFlag = true, artifactExceptions = []) {
    let reviewPanelActivation = { ...this.state.reviewPanelActivation };
    for (const artifactId in reviewPanelActivation[findingCategoryId]) {
      reviewPanelActivation[findingCategoryId][artifactId] = (!(artifactExceptions.includes(artifactId))) ? activationFlag : !activationFlag;
    }
    this.setState({ reviewPanelActivation });
  }

  acceptDeveloperReview(findingCategoryId, artifactLocation) {
    let findingsUnderReview = { ...this.state.findingsUnderReview };
    const findingKey = this.getFindingKey(findingCategoryId, artifactLocation, this.USER_ROLE_DEVELOPER);
    if (findingKey !== undefined && findingKey in findingsUnderReview[findingCategoryId][this.USER_ROLE_DEVELOPER]) {
      const developerReview = findingsUnderReview[findingCategoryId][this.USER_ROLE_DEVELOPER][findingKey];
      const curDateTime = new Date()
      const reviewContent = {
        reviewRequester: this.userId,
        reviewLastUpdate: curDateTime,
        reviewAction: this.REVIEW_ACTION_AGREE_WITH_DEVELOPER_REVIEW,
        reviewReason: "I agree with the review feedback provided to this finding by "
          + developerReview.reviewRequester
          + " on "
          + this.formatDateTimeUTCToLocalTimeZone(developerReview.reviewLastUpdate),
        reviewType: developerReview.reviewType,
        reviewValue: developerReview.reviewValue,
        reviewCompleted: true,
      };
      // developer did a per REPO review
      if (findingKey === this.ALL_FILES) {
        findingsUnderReview[findingCategoryId][this.USER_ROLE_EXPERT] = {
          reviewScope: this.SCOPE_REPO,
          [this.ALL_FILES]: reviewContent
        }
        this.updateReviewPanelActivationState(findingCategoryId);
      }
      // developer did a per FILE review
      else {
        if (this.ALL_FILES in findingsUnderReview[findingCategoryId][this.USER_ROLE_EXPERT]) {
          let reviewsStructure = this.buildEmptyPerFileReviewStructure(findingCategoryId);
          reviewsStructure[artifactLocation] = reviewContent
          findingsUnderReview[findingCategoryId][this.USER_ROLE_EXPERT] = reviewsStructure;
        }
        else {
          findingsUnderReview[findingCategoryId][this.USER_ROLE_EXPERT].reviewScope = this.SCOPE_FILE;
          findingsUnderReview[findingCategoryId][this.USER_ROLE_EXPERT][artifactLocation] = reviewContent;
        }
      }
    }
    console.log(findingsUnderReview);
    this.setState({ findingsUnderReview });
  }

  acceptOriginalAssessment(findingCategoryId, artifactLocation, requestedReviewScope, reviewAction, userRole = this.userRole) {
    let findingsUnderReview = { ...this.state.findingsUnderReview };
    const curDateTime = new Date();
    const reviewContent = {
      reviewRequester: this.userId,
      reviewLastUpdate: curDateTime,
      reviewReason: `I agree with the original assessement of this finding (scope: ${requestedReviewScope})`,
      reviewType: this.REVIEW_TYPE_SEVERITY,
      reviewValue: this.getOriginalFindingSeverity(findingCategoryId),
      reviewCompleted: true,
      reviewAction: reviewAction,
    };
    if (requestedReviewScope === this.SCOPE_REPO || requestedReviewScope === this.SCOPE_ORGANIZATION) {
      findingsUnderReview[findingCategoryId][userRole] = {
        reviewScope: requestedReviewScope,
        [this.ALL_FILES]: reviewContent
      }
      this.updateReviewPanelActivationState(findingCategoryId);
    }
    else if (requestedReviewScope === this.SCOPE_FILE) {
      if (this.ALL_FILES in findingsUnderReview[findingCategoryId][userRole]) {
        let reviewsStructure = this.buildEmptyPerFileReviewStructure(findingCategoryId);
        reviewsStructure[artifactLocation] = reviewContent
        findingsUnderReview[findingCategoryId][userRole] = reviewsStructure;
      }
      else {
        findingsUnderReview[findingCategoryId][userRole].reviewScope = this.SCOPE_FILE;
        findingsUnderReview[findingCategoryId][userRole][artifactLocation] = reviewContent;
      }
    }
    console.log(findingsUnderReview);
    this.setState({ findingsUnderReview });
  }

  willProvideOwnExpertAssessement(findingCategoryId, artifactLocation) {
    let findingsUnderReview = { ...this.state.findingsUnderReview };
    const findingKey = this.getFindingKey(findingCategoryId, artifactLocation, this.USER_ROLE_EXPERT);
    if (findingKey !== undefined && findingKey in findingsUnderReview[findingCategoryId][this.USER_ROLE_EXPERT]) {
      findingsUnderReview[findingCategoryId][this.USER_ROLE_EXPERT][findingKey] = {
        reviewCompleted: false,
        reviewAction: this.REVIEW_ACTION_OWN_ASSESSMENT,
      }
      console.log(findingsUnderReview);
      this.setState({ findingsUnderReview });
    }
  }

  resetReview(findingCategoryId, userRole, artifactLocation) {
    let findingsUnderReview = { ...this.state.findingsUnderReview };
    const findingKey = this.getFindingKey(findingCategoryId, artifactLocation, userRole);
    findingsUnderReview[findingCategoryId][userRole][findingKey] = {
      reviewCompleted: false,
    }
    this.setState({ findingsUnderReview });
  }

  buildEmptyPerFileReviewStructure(findingCategoryId) {
    const artifactLocations = this.getArtifactLocations(findingCategoryId);
    const reviewContent = {
      reviewScope: this.SCOPE_FILE,
    }
    artifactLocations.forEach((location) => {
      reviewContent[location] = {
        reviewCompleted: false,
      }
    })
    return reviewContent;
  }

  findingHasAFetchedReview(findingCategoryId, userRole, artifactLocation) {
    const { fetchedReviews } = this.state;
    if ("reviews" in fetchedReviews[userRole] && findingCategoryId in fetchedReviews[userRole].reviews) {
      const reviewsStructure = fetchedReviews[userRole].reviews[findingCategoryId];
      return this.ALL_FILES in reviewsStructure || artifactLocation in reviewsStructure;
    }
    return false;
  }

  getReviewClassification(findingCategoryId, userRole, artifactLocation) {
    const reviewsStructure = this.state.findingsUnderReview[findingCategoryId][userRole];
    const findingKey = this.getFindingKey(findingCategoryId, artifactLocation, userRole);
    if (findingKey in reviewsStructure) {
      const { reviewCompleted, reviewLastUpdate, reviewAction } = reviewsStructure[findingKey];
      if (!reviewCompleted)
        return this.REVIEW_CLASSIFICATION_INCOMPLETE_OR_NOT_STARTED_REVIEW;
      const { findingsReviewAPIFetchTime } = this.state;
      if (reviewLastUpdate < findingsReviewAPIFetchTime) {
        if (reviewAction === this.REVIEW_ACTION_AGREE_WITH_ORIGINAL_ASSESSMENT_FOR_FILE ||
          reviewAction === this.REVIEW_ACTION_AGREE_WITH_ORIGINAL_ASSESSMENT_FOR_REPO) {
          return this.REVIEW_CLASSIFICATION_LATEST_FETCHED_REVIEW_SAMEAS_ORIGINAL;
        }
        return this.REVIEW_CLASSIFICATION_LATEST_FETCHED_REVIEW_DIFFERENT_FROM_ORIGINAL;
      }
      return this.REVIEW_CLASSIFICATION_NEW_REVIEW_COMPLETED;
    }
    return this.REVIEW_CLASSIFICATION_INCOMPLETE_OR_NOT_STARTED_REVIEW
  }

  getReviewScopes() {
    const reviewScopes = [
      {
        key: 'file',
        text: 'this FILE only',
        value: this.SCOPE_FILE
      },
      {
        key: 'repo',
        text: 'ALL FILES in this repository',
        value: this.SCOPE_REPO
      },
    ];
    if (this.userRole === this.USER_ROLE_EXPERT) {
      reviewScopes.push({
        key: 'org',
        text: 'all REPOS in this organization',
        value: this.SCOPE_ORGANIZATION
      });
    }
    return reviewScopes;
  }

  renderQualityFindingReviewContent(artifactId, findingCategoryId, currentSeverity) {
    if (this.userRole in this.state.findingsUnderReview[findingCategoryId]) {
      const findingKey = this.getFindingKey(findingCategoryId, artifactId);
      if (findingKey in this.state.findingsUnderReview[findingCategoryId][this.userRole]) {
        const { reviewScope } = this.state.findingsUnderReview[findingCategoryId][this.userRole];
        const { reviewAction, reviewType, reviewValue, reviewReason, reviewCompleted, reviewRequester, reviewLastUpdate } = this.state.findingsUnderReview[findingCategoryId][this.userRole][findingKey];
        const reviewClassification = this.getReviewClassification(findingCategoryId, this.userRole, artifactId);
        let severityOptions = [];
        this.severityValues.map((severity) => {
          severityOptions.push(
            {
              key: findingKey + '-severity-' + severity,
              text: (currentSeverity === severity ? severity + ' (current)' : severity),
              disabled: (currentSeverity === severity),
              value: severity
            });
        });
        const reviewScopes = this.getReviewScopes();
        const handleReviewItemChange = (e, data) => {
          const curDateTime = new Date()
          let findingsUnderReview = { ...this.state.findingsUnderReview };
          const findingKey = this.getFindingKey(findingCategoryId, artifactId);
          const reviewsStructure = findingsUnderReview[findingCategoryId][this.userRole];
          let reviewContent = reviewsStructure[findingKey];
          reviewContent[data.name] = data.value;
          if (data.name === 'reviewType' && data.value === this.REVIEW_TYPE_SUPPRESSION) {
            reviewContent.reviewValue = '';
          }
          reviewContent.reviewRequester = this.userId;
          reviewContent.reviewLastUpdate = curDateTime;
          reviewContent.reviewAction = this.REVIEW_ACTION_OWN_ASSESSMENT;
          reviewContent.reviewCompleted = this.isReviewReadyForSubmission(reviewsStructure, findingKey);
          this.setState({ findingsUnderReview });
        }

        const handleReviewScopeChange = (e, data) => {
          const requestedScope = data.value;
          const curDateTime = new Date()
          let findingsUnderReview = { ...this.state.findingsUnderReview };
          findingsUnderReview[findingCategoryId][this.userRole].reviewScope = requestedScope;

          // set per FILE scope
          if (requestedScope === this.SCOPE_FILE && this.isAPerRepoReview(findingCategoryId)) {
            let reviewsStructure = this.buildEmptyPerFileReviewStructure(findingCategoryId);
            reviewsStructure[artifactId] = {
              ...findingsUnderReview[findingCategoryId][this.userRole][this.ALL_FILES],
              reviewRequester: this.userId,
              reviewLastUpdate: curDateTime,
              reviewAction: this.REVIEW_ACTION_OWN_ASSESSMENT,
            }
            reviewsStructure[artifactId].reviewCompleted = this.isReviewReadyForSubmission(reviewsStructure, artifactId);
            findingsUnderReview[findingCategoryId][this.userRole] = reviewsStructure;
            this.updateReviewPanelActivationState(findingCategoryId, false, [artifactId])
          }
          // set per REPO or per ORG scope
          else if (requestedScope === this.SCOPE_REPO || requestedScope === this.SCOPE_ORGANIZATION) {
            const findingKey = this.getFindingKey(findingCategoryId, artifactId);
            const reviewContent = findingsUnderReview[findingCategoryId][this.userRole][findingKey];
            let reviewsStructure = {
              reviewScope: requestedScope,
              [this.ALL_FILES]: {
                ...reviewContent,
                reviewRequester: this.userId,
                reviewLastUpdate: curDateTime,
                reviewAction: this.REVIEW_ACTION_OWN_ASSESSMENT,
              },
            }
            reviewsStructure[this.ALL_FILES].reviewCompleted = this.isReviewReadyForSubmission(reviewsStructure, this.ALL_FILES);
            findingsUnderReview[findingCategoryId][this.userRole] = reviewsStructure;
            this.updateReviewPanelActivationState(findingCategoryId);
          }
          this.setState({ findingsUnderReview });
        }

        const handleResetReviewAction = (e, data) => {
          this.resetReview(findingCategoryId, this.userRole, artifactId);
        }

        const handleDiscardReviewAction = (e, data) => {
          const existingReviewScope = this.state.findingsUnderReview[findingCategoryId][this.userRole].reviewScope;
          const reviewAction = existingReviewScope === this.SCOPE_FILE ? this.REVIEW_ACTION_AGREE_WITH_ORIGINAL_ASSESSMENT_FOR_FILE : this.REVIEW_ACTION_AGREE_WITH_ORIGINAL_ASSESSMENT_FOR_REPO;
          this.acceptOriginalAssessment(findingCategoryId, artifactId, existingReviewScope, reviewAction, this.userRole);
        }

        const handleExpertReviewChoiceChange = (e, data) => {
          if (data.value === this.REVIEW_ACTION_AGREE_WITH_DEVELOPER_REVIEW) {
            this.acceptDeveloperReview(findingCategoryId, artifactId)
          }
          else if (data.value === this.REVIEW_ACTION_AGREE_WITH_ORIGINAL_ASSESSMENT_FOR_FILE) {
            this.acceptOriginalAssessment(findingCategoryId, artifactId, this.SCOPE_FILE, data.value)
          }
          else if (data.value === this.REVIEW_ACTION_AGREE_WITH_ORIGINAL_ASSESSMENT_FOR_REPO) {
            this.acceptOriginalAssessment(findingCategoryId, artifactId, this.SCOPE_REPO, data.value)
          }
          else if (data.value === this.REVIEW_ACTION_OWN_ASSESSMENT) {
            this.willProvideOwnExpertAssessement(findingCategoryId, artifactId)
          }
        }

        const shouldDisableReviewForm = (reviewAction) => {
          return reviewAction !== undefined && reviewAction !== this.REVIEW_ACTION_OWN_ASSESSMENT;
        }

        return (
          <>
            <Header as="h4">{this.userRole === this.USER_ROLE_EXPERT ? 'I have an opinion on this finding (Expert Review)' : 'I have an opinion on this finding (Developer Review)'}</Header>
            {
              this.userRole === this.USER_ROLE_EXPERT &&
              <Message>
                <Form>
                  {
                    this.roleHasReviewedFinding(findingCategoryId, this.USER_ROLE_DEVELOPER, artifactId) &&
                    <Form.Field>
                      <Checkbox
                        radio
                        label='I agree with the Developer Feedback Review above'
                        name='agreeWithUserReview'
                        value={this.REVIEW_ACTION_AGREE_WITH_DEVELOPER_REVIEW}
                        checked={reviewAction !== undefined && reviewAction === this.REVIEW_ACTION_AGREE_WITH_DEVELOPER_REVIEW}
                        onChange={handleExpertReviewChoiceChange}
                      />
                    </Form.Field>
                  }
                  <Form.Field>
                    <Checkbox
                      radio
                      label={'I agree with the Original Assessment in the scope of this FILE'}
                      name='agreeWithOriginalAssessmentThisFile'
                      value={this.REVIEW_ACTION_AGREE_WITH_ORIGINAL_ASSESSMENT_FOR_FILE}
                      checked={reviewAction !== undefined && reviewAction === this.REVIEW_ACTION_AGREE_WITH_ORIGINAL_ASSESSMENT_FOR_FILE}
                      onChange={handleExpertReviewChoiceChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      radio
                      label={'I agree with the Original Assessment in the scope of this REPO'}
                      name='agreeWithOriginalAssessmentThisRepo'
                      value={this.REVIEW_ACTION_AGREE_WITH_ORIGINAL_ASSESSMENT_FOR_REPO}
                      checked={reviewAction !== undefined && reviewAction === this.REVIEW_ACTION_AGREE_WITH_ORIGINAL_ASSESSMENT_FOR_REPO}
                      onChange={handleExpertReviewChoiceChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      radio
                      label='I am providing my own assessment below'
                      name='provideOwnAssessment'
                      value={this.REVIEW_ACTION_OWN_ASSESSMENT}
                      checked={reviewAction !== undefined && reviewAction === this.REVIEW_ACTION_OWN_ASSESSMENT}
                      onChange={handleExpertReviewChoiceChange}
                    />
                  </Form.Field>
                </Form>
              </Message>
            }
            <Segment>
              {
                reviewRequester !== undefined &&
                <Label basic attached='top left' size="tiny" color="black">
                  <Icon name='user' />
                  By {reviewRequester} on {this.formatDateTimeUTCToLocalTimeZone(reviewLastUpdate)}
                </Label>
              }
              <Form>
                <Form.Field
                  disabled={shouldDisableReviewForm(reviewAction)}
                >
                  <Checkbox
                    radio
                    label='I suggest suppressing this finding'
                    name='reviewType'
                    value={this.REVIEW_TYPE_SUPPRESSION}
                    checked={reviewType === this.REVIEW_TYPE_SUPPRESSION}
                    onChange={handleReviewItemChange}
                  />
                </Form.Field>
                <Form.Field
                  disabled={shouldDisableReviewForm(reviewAction)}
                >
                  <Grid>
                    <Grid.Column>
                      <Checkbox
                        radio
                        label="I suggest changing the severity of this finding to &nbsp;"
                        name='reviewType'
                        value={this.REVIEW_TYPE_SEVERITY}
                        checked={reviewType === this.REVIEW_TYPE_SEVERITY}
                        onChange={handleReviewItemChange}
                      />
                      <Dropdown
                        placeholder='Select a severity'
                        name='reviewValue'
                        selection
                        disabled={reviewType !== this.REVIEW_TYPE_SEVERITY}
                        options={severityOptions}
                        value={reviewValue || ''}
                        onChange={handleReviewItemChange}
                      />
                    </Grid.Column>
                  </Grid>
                </Form.Field>
                <Form.Field
                  disabled={shouldDisableReviewForm(reviewAction)}
                >
                  <Grid>
                    <Grid.Column>
                      My review of this finding applies to &nbsp;
                      <Dropdown
                        selection
                        name='reviewScope'
                        options={reviewScopes}
                        value={reviewScope || ''}
                        onChange={handleReviewScopeChange}
                      />
                    </Grid.Column>
                  </Grid>
                </Form.Field>
                <Form.Field
                  disabled={shouldDisableReviewForm(reviewAction)}
                >
                  Comments:
                  <TextArea
                    placeholder='Please provide a concise but relevant explanation of your review.'
                    name='reviewReason'
                    value={reviewReason || ''}
                    onChange={handleReviewItemChange}
                  />
                </Form.Field>

                {
                  this.userRole === this.USER_ROLE_EXPERT &&
                  <Button secondary
                    disabled={shouldDisableReviewForm(reviewAction)}
                    size="tiny"
                    onClick={handleResetReviewAction}>
                    <Icon name='user cancel' />
                    Reset Review
                  </Button>
                }

                {
                  this.userRole === this.USER_ROLE_DEVELOPER &&
                  (
                    reviewClassification === this.REVIEW_CLASSIFICATION_INCOMPLETE_OR_NOT_STARTED_REVIEW ||
                    reviewClassification === this.REVIEW_CLASSIFICATION_NEW_REVIEW_COMPLETED ||
                    reviewClassification === this.REVIEW_CLASSIFICATION_LATEST_FETCHED_REVIEW_SAMEAS_ORIGINAL
                  ) &&
                  <Button secondary
                    size="tiny"
                    onClick={handleResetReviewAction}>
                    <Icon name='user cancel' />
                    Reset Review
                  </Button>
                }

                {
                  this.userRole === this.USER_ROLE_DEVELOPER &&
                  reviewClassification === this.REVIEW_CLASSIFICATION_LATEST_FETCHED_REVIEW_DIFFERENT_FROM_ORIGINAL &&
                  <Button secondary
                    size="tiny"
                    onClick={handleDiscardReviewAction}>
                    <Icon name='undo' />
                    Discard Review and Accept Original Automated Assessment
                  </Button>
                }

              </Form >
              {
                !reviewCompleted &&
                <Label basic attached='top right' size="tiny" color="blue">
                  <Icon name='hand point down outline' /> Review Incomplete
                </Label>
              }
              {
                reviewClassification === this.REVIEW_CLASSIFICATION_NEW_REVIEW_COMPLETED &&
                <Label basic attached='top right' size="tiny" color="green">
                  <Icon name='checkmark' />
                  Review Completed
                </Label>
              }
              {
                (reviewClassification === this.REVIEW_CLASSIFICATION_LATEST_FETCHED_REVIEW_DIFFERENT_FROM_ORIGINAL ||
                  reviewClassification === this.REVIEW_CLASSIFICATION_LATEST_FETCHED_REVIEW_SAMEAS_ORIGINAL) &&
                <Label basic attached='top right' size="tiny" color="orange">
                  <Icon name='checkmark' />
                  Latest Fetched Review
                </Label>
              }
            </Segment>
          </>
        )
      }
    }
    return (<></>)
  }

  renderFindingLastReviewContent(artifactLocation, findingCategoryId) {
    const theOtherUserRole = this.theOtherUserRole();
    if (theOtherUserRole in this.state.findingsUnderReview[findingCategoryId]) {
      const findingKey = this.getFindingKey(findingCategoryId, artifactLocation, theOtherUserRole);
      const reviewContent = this.state.findingsUnderReview[findingCategoryId][theOtherUserRole];
      if (findingKey in reviewContent) {
        const { reviewScope } = reviewContent;
        if (reviewScope !== undefined) {
          const { reviewType, reviewValue, reviewReason, reviewRequester, reviewLastUpdate, reviewCompleted } = reviewContent[findingKey];
          if (reviewCompleted) {
            const positiveMessage = (theOtherUserRole === this.USER_ROLE_EXPERT);
            return (
              <>
                <Message info={!positiveMessage} positive={positiveMessage}>
                  <Message.Header>{theOtherUserRole === this.USER_ROLE_EXPERT ? 'Expert Review Feedback' : 'Developer Review Feedback'}</Message.Header>
                  <p>
                    <b>{reviewRequester}</b> reviewed this finding on <b>{this.formatDateTimeUTCToLocalTimeZone(reviewLastUpdate)}</b>
                    {
                      <>
                        {
                          theOtherUserRole === this.USER_ROLE_EXPERT &&
                          reviewType === this.REVIEW_TYPE_SUPPRESSION &&
                          <>&nbsp;and indicated that this finding should be <b>suppressed</b>
                            {reviewScope === this.SCOPE_ORGANIZATION && <>&nbsp;for <b>all repositories</b> in your organization</>}
                            {reviewScope === this.SCOPE_REPO && <>&nbsp;for <b>all files</b> in this repository</>}
                            {reviewScope === this.SCOPE_FILE && <>&nbsp;for this file</>}
                            .</>
                        }
                        {
                          theOtherUserRole === this.USER_ROLE_EXPERT &&
                          reviewType === this.REVIEW_TYPE_SEVERITY &&
                          <>&nbsp;and indicated that this finding's <b>severity</b> should be <b>{reviewValue}</b>
                            {reviewScope === this.SCOPE_ORGANIZATION && <>&nbsp;for <b>all repositories</b> in your organization</>}
                            {reviewScope === this.SCOPE_REPO && <>&nbsp;for <b>all files</b> in this repository</>}
                            {reviewScope === this.SCOPE_FILE && <>&nbsp;for this <b>file</b></>}
                            .</>
                        }
                        {
                          theOtherUserRole === this.USER_ROLE_DEVELOPER &&
                          reviewType === this.REVIEW_TYPE_SUPPRESSION &&
                          <>&nbsp;and requested this finding to be <b>suppressed</b>
                            {reviewScope === this.SCOPE_ORGANIZATION && <>&nbsp;for <b>all repositories</b> in your organization</>}
                            {reviewScope === this.SCOPE_REPO && <>&nbsp;for <b>all files</b> in this repository</>}
                            {reviewScope === this.SCOPE_FILE && <>&nbsp;for this <b>file</b></>}
                            .</>
                        }
                        {
                          theOtherUserRole === this.USER_ROLE_DEVELOPER &&
                          reviewType === this.REVIEW_TYPE_SEVERITY &&
                          <>&nbsp;and requested this finding's <b>severity</b> to be set to <b>{reviewValue}</b>
                            {reviewScope === this.SCOPE_ORGANIZATION && <>&nbsp;for <b>all repositories</b> in your organization</>}
                            {reviewScope === this.SCOPE_REPO && <>&nbsp;for <b>all files</b> in this repository</>}
                            {reviewScope === this.SCOPE_FILE && <>&nbsp;for this <b>file</b></>}
                            .</>
                        }
                        &nbsp;Comments from the reviewer: <i>"{reviewReason}"</i>
                      </>
                    }
                  </p>
                </Message>
              </>
            )
          }
        }
      }
    }
    return (<></>)
  }

  createGenAIPrompt(finding_description) {
    const description = finding_description.replace(/[\n\r\t]/g, ' ')
    return `Source Code:\n<<PASTE THE CODE TO BE FIXED HERE>>\n\nCode Analysis Finding: \n${description}\n\nRequest:\nGiven the source code and the code analysis finding above, please provide a fix to the code.\nThe response should be in markdown\n`
  }

  renderSourceCodeFixSuggestionGenAI(artifactId, findingDetails) {
    const { description, finding_category_id } = findingDetails;
    const { genAISourceCodeFixSuggestionLoadingState } = this.state;
    const { displayPanel, loadingState, prompt, result, model } = genAISourceCodeFixSuggestionLoadingState[finding_category_id][artifactId];
    const genAIDisplayButtonLabel = displayPanel ? "Close Generative AI Panel" : "Open Generative AI Panel!";
    const markdownContent = result;
    const promptSize = (prompt === null ? 0 : prompt.length);
    const maxPromptSizeAlertMessage = (
      promptSize === 0 ?
        '' :
        (promptSize === this.GENAI_MAX_PROMPT_SIZE ?
          'Max Prompt size reached: ' + prompt.length + ' out of ' + this.GENAI_MAX_PROMPT_SIZE + ' max characters (text might have been truncated)' :
          'Prompt size: ' + prompt.length + ' out of ' + this.GENAI_MAX_PROMPT_SIZE + ' max characters'
        )
    );

    const handleGenAIDisplayPanelButtonClick = () => {
      const genAISourceCodeFixSuggestionLoadingState = { ...this.state.genAISourceCodeFixSuggestionLoadingState };
      genAISourceCodeFixSuggestionLoadingState[finding_category_id][artifactId].displayPanel = !displayPanel;
      if (prompt === null) {
        genAISourceCodeFixSuggestionLoadingState[finding_category_id][artifactId].prompt = this.createGenAIPrompt(description);
      }
      this.setState({ genAISourceCodeFixSuggestionLoadingState });
    }

    const handleGenAIPromptChange = (e, data) => {
      const genAISourceCodeFixSuggestionLoadingState = { ...this.state.genAISourceCodeFixSuggestionLoadingState };
      const updatedPrompt = data.value.slice(0, this.GENAI_MAX_PROMPT_SIZE);
      genAISourceCodeFixSuggestionLoadingState[finding_category_id][artifactId].prompt = updatedPrompt;
      this.setState({ genAISourceCodeFixSuggestionLoadingState });
    }

    const handleGenAIModelChange = (e, data) => {
      const genAISourceCodeFixSuggestionLoadingState = { ...this.state.genAISourceCodeFixSuggestionLoadingState };
      genAISourceCodeFixSuggestionLoadingState[finding_category_id][artifactId].model = data.value;
      this.setState({ genAISourceCodeFixSuggestionLoadingState });
    }

    const fetchSourceCodeFixSuggestionGenAI = () => {

      const updateGenAISourceCodeFixSuggestionLoadingState = (loadingState, genAIResult = null) => {
        const genAISourceCodeFixSuggestionLoadingState = { ...this.state.genAISourceCodeFixSuggestionLoadingState };
        genAISourceCodeFixSuggestionLoadingState[finding_category_id][artifactId].loadingState = loadingState;
        if (loadingState === this.SOURCE_CODE_FIX_GENAI_FETCHED) {
          genAISourceCodeFixSuggestionLoadingState[finding_category_id][artifactId].result = genAIResult;
        }
        else if (loadingState === this.SOURCE_CODE_FIX_GENAI_FETCHING_ERROR) {
          genAISourceCodeFixSuggestionLoadingState[finding_category_id][artifactId].result = 'Unexpected error processing the prompt. Please contact the QAP team.';
        }
        this.setState({ genAISourceCodeFixSuggestionLoadingState });
      }

      let postBody = {
        body: {
          model: model,
          prompt: prompt,
        }
      }
      console.log(`Calling API: ${this.API_GEN_AI_CODE_FIX_SUGGESTION_PATH}`)
      console.log(`Post body: ${JSON.stringify(postBody)}`)
      updateGenAISourceCodeFixSuggestionLoadingState(this.SOURCE_CODE_FIX_GENAI_FETCHING);
      API.post(this.API_NAME, this.API_GEN_AI_CODE_FIX_SUGGESTION_PATH, postBody)
        .then(response => {
          updateGenAISourceCodeFixSuggestionLoadingState(this.SOURCE_CODE_FIX_GENAI_FETCHED, response);
          console.log(response);
        })
        .catch(error => {
          updateGenAISourceCodeFixSuggestionLoadingState(this.SOURCE_CODE_FIX_GENAI_FETCHING_ERROR);
          console.error(error)
        });
    }

    return (
      <Segment basic={displayPanel === false}>
        <Button fluid
          color="blue"
          content={genAIDisplayButtonLabel}
          icon='magic'
          onClick={handleGenAIDisplayPanelButtonClick}
        />
        {
          displayPanel &&
          <Segment basic>
            <Form>
              <Form.Field>
                <Header as='h4' color='teal' textAlign='left'>
                  Complete the GenAI Prompt below (e.g., copy/paste desired code into the appropriate area or build your own prompt)
                </Header>
                <div>
                  &nbsp;{promptSize === this.GENAI_MAX_PROMPT_SIZE && <Icon name="exclamation triangle" />}
                  {maxPromptSizeAlertMessage}
                </div>
                <TextArea
                  style={{ width: '100%', height: '260px' }}
                  name='genAIPrompt'
                  value={prompt}
                  onChange={handleGenAIPromptChange}
                />
              </Form.Field>
              <Form.Field>
                <Grid columns={2} verticalAlign="middle">
                  <Grid.Column textAlign="left">
                    <Dropdown
                      placeholder='Select a model'
                      name='genAIModelDropdown'
                      selection
                      options={this.genAISupportedModels}
                      value={model}
                      onChange={handleGenAIModelChange}
                    />
                  </Grid.Column>
                  <Grid.Column textAlign="right">
                    <Button
                      secondary
                      loading={loadingState === this.SOURCE_CODE_FIX_GENAI_FETCHING}
                      content="Submit My Prompt!"
                      onClick={fetchSourceCodeFixSuggestionGenAI}
                    />
                  </Grid.Column>
                </Grid>
              </Form.Field>
            </Form>
            {
              result != null &&
              <>
                <Divider />
                <Header as='h4' color='teal' textAlign='left'>
                  GenAI Output:
                </Header>
                <ReactMarkdown>{markdownContent}</ReactMarkdown>
              </>
            }
          </Segment>
        }
      </Segment>
    )
  }

  renderSourceCode(artifactId, findingDetails) {
    const { sourceCodeLoadingState } = this.state;

    const getLinesToHighlight = (finding_details) => {
      const { start_line, end_line, line_numbers } = finding_details;
      if (line_numbers.length > 0) {
        return line_numbers.map(Number);
      }
      if (start_line !== "-1") {
        if (end_line === start_line) {
          return [Number(start_line)];
        }
        return Array.from({ length: Number(end_line) - Number(start_line) + 1 }, (_, index) => Number(start_line) + index);
      }
      return [];
    }

    const fetchSourceCode = () => {

      const updateSourceCodeLoadingState = (loadingState, fetchedSourceCode = null) => {
        const sourceCodeLoadingState = { ...this.state.sourceCodeLoadingState };
        sourceCodeLoadingState[artifactId] = loadingState;
        if (loadingState === this.SOURCE_CODE_FETCHED) {
          this.fetchedSourceCode[artifactId] = {
            sourceCode: fetchedSourceCode,
            programmingLanguage: 'text' // hljs.highlightAuto(fetchedSourceCode).language,  // syntax highlighting is slowing things down quite a bit
          };
        }
        this.setState({ sourceCodeLoadingState });
      }

      if (sourceCodeLoadingState[artifactId] === this.SOURCE_CODE_NOT_FETCHED) {
        const { data } = this.props;
        const repositoryName = data.prototype_details.code_repository_name;
        const commitId = data.commit_id;
        const queryString = `repositoryName=${repositoryName}&commitId=${commitId}&filePath=${artifactId}`
        console.log(`Calling API: ${this.API_FETCH_SOURCE_CODE_PATH}?${queryString}`)
        updateSourceCodeLoadingState(this.SOURCE_CODE_FETCHING);
        API.get(this.API_NAME, `${this.API_FETCH_SOURCE_CODE_PATH}?${queryString}`)
          .then(response => {
            updateSourceCodeLoadingState(this.SOURCE_CODE_FETCHED, response.join('\r\n'));
          })
          .catch(error => {
            updateSourceCodeLoadingState(this.SOURCE_CODE_FETCHING_ERROR);
            console.error(error)
          });
      }
    }

    fetchSourceCode();

    return (
      <>
        {
          sourceCodeLoadingState[artifactId] === this.SOURCE_CODE_FETCHED &&
          <Segment basic>
            <Header as="h5">Source Code:</Header>
            <Container style={{ overflow: 'auto', maxHeight: 340, width: "100%" }}>
              <SyntaxHighlighter
                language={this.fetchedSourceCode[artifactId].programmingLanguage}
                style={darcula}
                showLineNumbers={true}
                wrapLines={true}
                lineProps={(lineNumber) => ({
                  style: getLinesToHighlight(findingDetails).includes(lineNumber) ? { foreground: "#000000", background: '#ffff66' } : {}
                })}
              >
                {this.fetchedSourceCode[artifactId].sourceCode}
              </SyntaxHighlighter>
            </Container>
            {this.renderSourceCodeFixSuggestionGenAI(artifactId, findingDetails)}
          </Segment>
        }
        {
          sourceCodeLoadingState[artifactId] === this.SOURCE_CODE_FETCHING_ERROR &&
          <Button fluid
            content='Ooops! This file does not exist in the repository or could not be fetched.' icon='attention'
          />
        }
        {
          (sourceCodeLoadingState[artifactId] === this.SOURCE_CODE_FETCHING) &&
          <Button fluid
            loading={sourceCodeLoadingState[artifactId] === this.SOURCE_CODE_FETCHING}
            content='Loading source code'
            icon='code'
            onClick={fetchSourceCode}
          />
        }
      </>
    )
  }

  renderQualityFindingReview(findingDetails, artifactId, findingCategoryId, currentSeverity) {
    if (findingCategoryId in this.state.findingsUnderReview) {
      const activateReviewPanel = this.state.reviewPanelActivation[findingCategoryId][artifactId];
      const latestReviewerRole = this.state.latestReviewerRole[findingCategoryId][artifactId];
      if (this.userRole in this.state.findingsUnderReview[findingCategoryId]) {
        const findingKey = this.getFindingKey(findingCategoryId, artifactId);
        const reviewsStructure = this.state.findingsUnderReview[findingCategoryId][this.userRole];
        if (findingKey in reviewsStructure) {

          const handleFindingReviewActivation = (e, titleProps) => {
            let reviewPanelActivation = { ...this.state.reviewPanelActivation };
            reviewPanelActivation[findingCategoryId][artifactId] = !reviewPanelActivation[findingCategoryId][artifactId];
            this.setState({ reviewPanelActivation });
          }

          return (
            <Accordion>
              <Accordion.Title
                active={activateReviewPanel}
                index={0}
                onClick={handleFindingReviewActivation}
              >
                <Icon name='dropdown' />
                Click Here to Explore Your Options to Fix this Finding&nbsp;
                {latestReviewerRole !== this.NO_REVIEWER && <Icon style={{color: "#CC0000"}} name='bell' />}
              </Accordion.Title>
              <Accordion.Content
                active={activateReviewPanel}
              >
                {activateReviewPanel && this.renderFindingLastReviewContent(artifactId, findingCategoryId)}
                {activateReviewPanel && this.renderSourceCode(artifactId, findingDetails)}
                {activateReviewPanel && this.renderQualityFindingReviewContent(artifactId, findingCategoryId, currentSeverity)}
              </Accordion.Content>
            </Accordion>
          )
        }
      }
    }
    return <></>
  }

  getLines(finding_details) {
    const { start_line, end_line, line_numbers } = finding_details;
    let lines = ""
    if (line_numbers.length > 0) {
      lines = line_numbers.join(', ');
    }
    else if (start_line === "-1") {
      lines = "-"
    } else if (end_line === "-1" || end_line === start_line) {
      lines = start_line;
    } else {
      lines = start_line + "-" + end_line;
    }
    return lines
  }

  renderQualityFinding(findingDetails, artifactId, index) {
    const { severity, original_severity, finding_category_id, description, expert_review } = findingDetails;
    let lines = this.getLines(findingDetails);
    const hasLoadedReviews = (this.state.findingsUnderReview !== undefined);

    return (
      <Table basic celled key={'table-' + finding_category_id + '-' + index}>
        <Table.Body>
          <Table.Row key={"finding-" + finding_category_id}>
            <Table.Cell>
              <div className={severity}>{severity}</div>
              {
                original_severity !== undefined &&
                original_severity !== severity &&
                <div className="crossline" title={"Revised by " + expert_review.reviewer + " on " + this.formatDateTimeUTCToLocalTimeZone(expert_review.review_date)}>{original_severity}</div>
              }
            </Table.Cell>
            <Table.Cell>{lines}</Table.Cell>
            <Table.Cell>
              <Label basic size="tiny" pointing="below">{finding_category_id}</Label>
              <br />
              {description}
            </Table.Cell>
          </Table.Row>
          {
            expert_review !== undefined &&
            expert_review['review_type'] === this.REVIEW_TYPE_SUPPRESSION &&
            <Table.Row key={"finding-note" + finding_category_id}>
              <Table.Cell colSpan={3}>
                <Button fluid basic
                  content='Currently invisible to developers due to expert suppression.'
                  icon='attention'
                  size="small"
                  color='red'
                />
              </Table.Cell>
            </Table.Row>
          }
          <Table.Row key={"finding-review-" + finding_category_id}>
            <Table.Cell colSpan={3}>
              {
                hasLoadedReviews &&
                this.renderQualityFindingReview(findingDetails, artifactId, finding_category_id, severity)
              }
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    )
  }
  truncateString(str, n) {
    if (str.length <= n)
      return str;
    return str.slice(0, n);
  }

  countFindingsForCategoryId(featureCategoryId) {
    let countFindings = 0;
    this.props.data.findings.forEach((artifact) => {
      const { artifact_findings, suppressed_findings } = artifact;
      const all_artifact_findings = (artifact_findings || []).concat(suppressed_findings || [])
      all_artifact_findings.forEach((finding) => {
        if (finding.finding_category_id === featureCategoryId) {
          countFindings++;
        }

      });
    });
    return countFindings;
  }

  prepareDataForSubmission() {
    const { findingsUnderReview, findingsReviewAPIFetchTime } = this.state;
    this.submissionData.reviews = {}
    this.submissionData.submissionDate = moment.utc(new Date()).toISOString();
    let countUpdatedReviews = 0;
    for (const findingCategoryId in findingsUnderReview) {
      const reviewsStructure = this.state.findingsUnderReview[findingCategoryId][this.userRole];
      for (const findingKey in reviewsStructure) {
        if (findingKey !== "reviewScope") {
          const reviewContent = reviewsStructure[findingKey];
          // include all and only completed reviews (by any user)
          if (reviewContent.reviewCompleted === true) {
            let numFilesImpactedByReview = 1;
            // was the review done after the last time the reviews were fetched via the API?
            if (reviewContent.reviewLastUpdate > findingsReviewAPIFetchTime) {
              if (findingKey === this.ALL_FILES) {
                numFilesImpactedByReview = this.countFindingsForCategoryId(findingCategoryId);
              }
              countUpdatedReviews += numFilesImpactedByReview;
            }
            if (!(findingCategoryId in this.submissionData.reviews)) {
              this.submissionData.reviews[findingCategoryId] = {
                reviewScope: reviewsStructure.reviewScope,
              }
            }
            this.submissionData.reviews[findingCategoryId][findingKey] = {
              reviewAction: reviewContent.reviewAction,
              reviewLastUpdate: moment.utc(reviewContent.reviewLastUpdate).toISOString(),
              reviewReason: this.truncateString(reviewContent.reviewReason, 200),
              reviewRequester: reviewContent.reviewRequester,
              reviewType: reviewContent.reviewType,
              reviewValue: reviewContent.reviewValue || '',
              reviewFile: findingKey,
              reviewNumFilesImpacted: numFilesImpactedByReview,
            }
          }
        }
      }
    }
    return countUpdatedReviews;
  }

  renderReviewsSubmissionModal(findingsUnderReview) {
    const { showSubmissionModal, submissionHasWarning, submissionMessage, lastSubmissionDate, submittingReviews } = this.state.reviewSubmission;

    const activateReviewsSubmissionModal = (activateModal) => {
      const reviewSubmission = { ...this.state.reviewSubmission };
      reviewSubmission.showSubmissionModal = !reviewSubmission.showSubmissionModal;
      if (activateModal) {
        const countUpdatedReviews = this.prepareDataForSubmission();
        if (countUpdatedReviews === 0) {
          reviewSubmission.submissionHasWarning = true;
          reviewSubmission.submissionMessage = "Please review or update at least one finding review before submitting.";
        }
        else {
          reviewSubmission.submissionHasWarning = false;
          reviewSubmission.submissionMessage = `${countUpdatedReviews} finding review(s) were updated and will be submitted. Do you confirm submission?`;
        }
      }
      else {
        reviewSubmission.submissionHasWarning = false;
        reviewSubmission.submissionMessage = "";
      }
      this.setState({ reviewSubmission });
    }

    const confirmReviewSubmission = async () => {
      const reviewSubmission = { ...this.state.reviewSubmission };
      console.log(this.submissionData);
      try {
        reviewSubmission.submittingReviews = true;
        this.setState({ reviewSubmission });
        let postBody = { 'body': this.submissionData }
        const response = await API.post(this.API_NAME, this.API_ANALYSIS_REVIEW_PATH, postBody);
        console.log(response);
        // reload the reviews
        await this.qualityReviewsSetup()
      }
      catch (error) {
        console.log(`Error submitting reviews: ${error}`);
      }
      finally {
        reviewSubmission.submittingReviews = false;
      }
      reviewSubmission.showSubmissionModal = false;
      reviewSubmission.submissionHasWarning = false;
      reviewSubmission.submissionMessage = "";
      reviewSubmission.lastSubmissionDate = this.submissionData.submissionDate;
      this.setState({ reviewSubmission });
    }

    return (
      <>
        <Form>
          {
            lastSubmissionDate !== null &&
            <Form.Field>
              <Label basic size="tiny">
                <Icon name='checkmark' />
                Last Submission: {this.formatDateTimeUTCToLocalTimeZone(lastSubmissionDate)} by {this.userId}
              </Label>
            </Form.Field>
          }
          <Form.Field>
            <Button
              primary
              disabled={findingsUnderReview === undefined || Object.keys(findingsUnderReview).length === 0}
              onClick={() => activateReviewsSubmissionModal(true)}
            >
              Submit Review
            </Button>
          </Form.Field>
        </Form>
        <Modal
          open={showSubmissionModal}
          onClose={() => activateReviewsSubmissionModal(false)}>
          <Modal.Header>Quality Findings Review Submission</Modal.Header>
          <Modal.Content>
            {
              submissionHasWarning &&
              <Message negative icon>
                <Icon name="attention" />
                {submissionMessage}
              </Message>
            }
            {
              !submissionHasWarning &&
              <Message positive icon>
                <Icon name="checkmark" />
                {submissionMessage}
              </Message>
            }
          </Modal.Content>
          <Modal.Actions>
            {
              submissionHasWarning &&
              <Button
                color="black"
                onClick={() => activateReviewsSubmissionModal(false)}
              >
                Ok
              </Button>
            }
            {
              !submissionHasWarning &&
              <Button
                color="green"
                disabled={submittingReviews}
                loading={submittingReviews}
                onClick={confirmReviewSubmission}
              >
                <Icon name='checkmark' /> Yes
              </Button>
            }
            {
              !submissionHasWarning &&
              <Button
                color="red"
                disabled={submittingReviews}
                loading={submittingReviews}
                onClick={() => activateReviewsSubmissionModal(false)}
              >
                <Icon name='remove' /> No
              </Button>
            }
          </Modal.Actions>
        </Modal >
      </ >
    );
  }

  renderQualityFindings() {

    const { findings } = this.props.data;
    const { findingsUnderReview } = this.state;
    return (
      <Segment basic>
        <Container>
          <Header as="h3" color="black">
            Quality Findings
          </Header>
          {
            this.sortArtifacts(findings).map((item, index) => {
              const { artifact_findings, suppressed_findings } = item;
              const all_artifact_findings = (artifact_findings || []).concat(suppressed_findings || [])
              const noFindingsToShowForFile = (
                (this.userRole === this.USER_ROLE_DEVELOPER && artifact_findings.length === 0) ||
                (this.userRole === this.USER_ROLE_EXPERT && all_artifact_findings.length === 0)
              );
              if (noFindingsToShowForFile) {
                return (<span key={index}></span>)
              }
              return (
                <div key={index}>
                  <Table celled key={index}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell colSpan={4}>
                          <Header as='h3' block color='black'>
                            <Icon name='file' size='small' />
                            <Header.Content>
                              {item['artifact_location']}{item['artifact_name']}
                            </Header.Content>
                          </Header>
                        </Table.HeaderCell>
                      </Table.Row>
                      <Table.Row>
                        <Table.HeaderCell>Severity</Table.HeaderCell>
                        <Table.HeaderCell>Line Number(s)</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell colSpan={3}>
                          {this.sortArtifactFindings(item['artifact_findings'] || []).map((findingDetails, index) => {
                            const artifactId = item['artifact_location'] + item['artifact_name'];
                            return this.renderQualityFinding(findingDetails, artifactId, index);
                          })}
                        </Table.Cell>
                      </Table.Row>
                      {
                        this.userRole === this.USER_ROLE_EXPERT &&
                        <Table.Row>
                          <Table.Cell colSpan={3}>
                            {this.sortArtifactFindings(item['suppressed_findings'] || []).map((findingDetails, index) => {
                              const artifactId = item['artifact_location'] + item['artifact_name'];
                              return this.renderQualityFinding(findingDetails, artifactId, index);
                            })}
                          </Table.Cell>
                        </Table.Row>
                      }
                    </Table.Body>
                  </Table>
                </div>
              )
            })
          }
          {
            this.props.enableQualityReviews &&
            <Segment basic textAlign="center">
              {
                this.renderReviewsSubmissionModal(findingsUnderReview)
              }
              <p>&nbsp;</p>
            </Segment>
          }
        </Container>
      </Segment>
    )
  }

  renderSummary() {
    const { total_number_of_files_inspected, total_number_of_findings, total_number_of_suppressed_findings, review_duration_in_secs } = this.props.data;
    return (
      <Segment basic>
        <Header as="h4">Summary</Header>
        <Table celled>
          <Table.Body>
            <Table.Row key="total_number_of_findings">
              <Table.Cell>Number of unsuppressed findings</Table.Cell>
              <Table.Cell>{total_number_of_findings}</Table.Cell>
            </Table.Row>
            <Table.Row key="total_number_of_suppressed_findings">
              <Table.Cell>Number of suppressed findings (after expert review)</Table.Cell>
              <Table.Cell>{total_number_of_suppressed_findings || 0}</Table.Cell>
            </Table.Row>
            <Table.Row key="total_number_of_files_inspected">
              <Table.Cell>Number of files inspected</Table.Cell>
              <Table.Cell>{total_number_of_files_inspected}</Table.Cell>
            </Table.Row>
            <Table.Row key="review_duration_in_secs">
              <Table.Cell>Analysis running time (secs)</Table.Cell>
              <Table.Cell>{review_duration_in_secs}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
    )
  }

  renderHeader() {
    const { category } = this.props.data;
    return (<Container>
      <Header as='h2' color='blue' textAlign='left'>{category}
      </Header>
    </Container>)
  }

  render() {
    const { result, findings } = this.props.data;
    return (
      <Segment basic>
        {this.renderHeader()}
        {this.renderSourceDetails()}
        {this.renderSummary()}
        {
          result === "FAILED" &&
          this.renderErrorMessage()
        }
        {
          findings.length > 0 &&
          this.renderQualityFindings()
        }
        {
          findings.length === 0 &&
          <Segment>
            <Header as="h4">No Findings!</Header>
          </Segment>
        }
      </Segment>
    );
  }

}